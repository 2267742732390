/*
 * Javascript for Asmodee North America
 */

require('foundation-sites/dist/js/foundation.js')

$(document).ready(function() {

  const mobileNav = $('.mobile-nav')
  const mobileNavli = mobileNav.find('li')
  const mobileNavNested = $('.mobile-nav > li > ul.nested')
  let accordionStatus = false

  $('.hamburger-button').click(function() {
    $(this).toggleClass('open')
    mobileNav.toggleClass('expand')
    mobileNav.removeClass('expand2')
    mobileNavNested.removeClass('expand')
    $('.dropdown-link').removeClass('dropdown-active')
    mobileNavNested.attr('data-subaccordion-status', 'close')
    accordionStatus = false;
  })

  $('.dropdown-link').click(function() {
    event.preventDefault()
    const accordionMenu = $(this).parent().find('.nested')
    const dropdownLink = $(this).parent().find('.dropdown-link')

    if (accordionMenu.is('[data-subaccordion-status=open]')) {
      accordionMenu.removeClass('expand')
      accordionMenu.attr('data-subaccordion-status', 'close')
      dropdownLink.removeClass('dropdown-active');
      mobileNavli.each(function() {

        // closest nested class selector in li elements
        $('.nested').is('[data-subaccordion-status=open]') ? (
          accordionStatus = true
        ) : (
          accordionStatus = false
        );
      })

      if (!accordionStatus) {
        mobileNav.removeClass('expand2')
      }

    } else {
      accordionMenu.addClass('expand')
      accordionMenu.attr('data-subaccordion-status', 'open')
      dropdownLink.addClass('dropdown-active');
      mobileNav.addClass('expand2')
    }
  })

  $(document).foundation();

})
